import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import {
    subpageHero,
    featuresSection,
    smaller,
    smallerMobile,
    larger,
    itemLeft,
    itemRight,
    smallImg,
} from './features.module.scss';

import GetStartedSection from '../components/GetStartedSection';
import { useAppSelector } from '../hooks';

const Features = () => {
    const language = useAppSelector((state) => state.global.language);

    return (
        <>
            <SEO
                title='Eiginleikar - Tilkynna'
                description='Eiginleikar - Tilkynna'
            />
            <Layout isFrontpage>
                <div>
                    <section className={subpageHero}>
                        <div>
                            <div>
                                <h1>Eiginleikar</h1>
                                <h2>
                                    Tilkynna er tilkynningakerfi sem einfaldar
                                    uppljóstrunarferlið, tryggir nafnleynd og
                                    einfaldar umsýslu tilkynninga.
                                </h2>
                            </div>
                        </div>
                    </section>

                    <section className={featuresSection}>
                        <div>
                            {/* <h2>Helstu </h2> */}

                            <div className={itemLeft}>
                                <div className={larger}>
                                    <h3>Lögaðili stofnar aðgang</h3>
                                    <p>
                                        Lögaðili stofnar aðgang að kerfi
                                        Tilkynna sem býr til sérstaka vefslóð
                                        fyrir tilkynnendur. Þar geta starfsmenn
                                        sem vilja tilkynna misferli fyllt inn
                                        upplýsingar varðandi málið. Starfsmaður
                                        getur sent tilkynninguna með nafnleynd
                                        og átt frekari samskipti í gegnum
                                        nafnlausa gátt.
                                    </p>
                                    <p>
                                        Fyllsta öryggis og trúnaðar er gætt við
                                        meðferð og meðhöndlun tilkynninga. Farið
                                        er með allar upplýsingar í samræmi við
                                        grundvallarsjónarmið og reglur um
                                        persónuvernd.
                                    </p>
                                </div>
                                <div className={smaller}>
                                    <img
                                        src='/features/createorganisation.png'
                                        alt=''
                                        className={smallImg}
                                    />
                                </div>
                                <div className={smallerMobile}>
                                    <img
                                        src='/features/createorganisation.png'
                                        alt=''
                                    />
                                </div>
                            </div>

                            <div className={itemRight}>
                                <div className={smaller}>
                                    <img
                                        src='/features/reportareas.png'
                                        alt=''
                                        className={smallImg}
                                    />
                                </div>

                                <div className={larger}>
                                    <h3>Mörg tilkynningasvæði</h3>
                                    <p>
                                        Lögaðili getur stofnað mörg mismunandi
                                        tilkynningasvæði. Það getur hentað
                                        stærri fyrirtækjum eða stofnunum til að
                                        aðgreina í sundur mismunandi hópa eins
                                        og deildir eða verktaka. Hvert
                                        tilkynningarsvæði fær sitt eigið
                                        undirlén, t.d.{' '}
                                        <i>verktakar-fyrirtaeki</i>.tilkynna.is
                                        og <i>starfsmenn-fyrirtaeki</i>
                                        .tilkynna.is.
                                    </p>
                                    <ul>
                                        <li>
                                            Hægt er að breyta og bæta við
                                            spurningum inn á hvert
                                            tilkynningarsvæði.
                                        </li>
                                        <li>
                                            Hægt er að búa til síður fyrir hvert
                                            tilkynningarsvæði eins og
                                            leiðbeiningar, verklagsreglur og
                                            persónuverndarstefnu.
                                        </li>
                                        <li>
                                            Hvert tilkynningasvæði getur haft
                                            mismunandi reglur. Sem dæmi þá er
                                            hægt er að setja lykilorð fyrir
                                            hvert svæði, velja hvaða
                                            persónuupplýsingar tilkynnendur
                                            þurfa að gefa upp og hvort þeir
                                            þurfi að auðkenna sig með rafrænum
                                            skilríkjum í gegnum island.is.
                                        </li>
                                    </ul>
                                </div>
                                <div className={smallerMobile}>
                                    <img
                                        src='/features/reportareas.png'
                                        alt=''
                                    />
                                </div>
                            </div>

                            <div className={itemLeft}>
                                <div className={larger}>
                                    <h3>Umsýsla tilkynninga</h3>
                                    <p>
                                        Við umsýslu tilkynninga er hægt að
                                        breyta málsheiti, setja stöðu málsins,
                                        bæta við umsjónaraðilum og skrá
                                        merkimiða. Þar er líka hægt að skrifa
                                        athugasemdir, til dæmis ef teymi innan
                                        lögaðila vill gefa álit á málinu. Einnig
                                        er hægt að eiga samskipti við
                                        tilkynnanda ef óskað er eftir frekari
                                        upplýsingum.
                                    </p>

                                    <ul>
                                        <li>
                                            Lögaðili getur stofnað mismunandi
                                            merkimiða til að auðvelda flokkun á
                                            tilkynningum og til að einfalda leit
                                            að tilkynningum.
                                        </li>
                                        <li>
                                            Lögaðili getur skráð einn eða fleiri
                                            umsjónaraðila á tilkynningu. Þeir fá
                                            tilkynningu í tölvupósti þegar ný
                                            skilaboð eða athugasemdir berast.
                                        </li>
                                        <li>
                                            Lögaðili getur hreinsað
                                            persónuupplýsingar úr tilkynningu,
                                            t.d. þegar mál hefur verið afgreitt
                                            og til að uppfylla kröfur
                                            Persónuverndar.
                                        </li>
                                    </ul>
                                    <p></p>
                                </div>
                                <div className={smaller}>
                                    <img src='/features/report.png' alt='' />
                                </div>
                                <div className={smallerMobile}>
                                    <img src='/features/report.png' alt='' />
                                </div>
                            </div>

                            <div className={itemRight}>
                                <div className={smaller}>
                                    <img
                                        src='/features/reportinvite.png'
                                        alt=''
                                    />
                                </div>

                                <div className={larger}>
                                    <h3>Boðskerfi fyrir tilkynningar</h3>
                                    <p>
                                        Umsjónaraðili getur boðið utanaðkomandi
                                        aðila inn á tilkynningu til þess að
                                        skrifa umsögn. Þetta eykur bæði öryggi
                                        og gagnsæi þar sem lögaðili þarf ekki að
                                        senda viðkvæmar upplýsingar tilkynnanda
                                        í gegnum tölvupóst eða önnur kerfi. Öll
                                        gögn og samskipti um málið eiga heima á
                                        einum stað hjá Tilkynna.
                                    </p>
                                    <p>Einnig er möguleiki á að:</p>
                                    <ul>
                                        <li>
                                            stofna tengil eða senda tengil með
                                            tölvupósti.
                                        </li>
                                        <li>
                                            stilla mismunandi réttindi á tengil,
                                            t.d. réttindi að skrifa athugasemd,
                                            lesa athugasemdir sem hafa verið
                                            skrifaðar og lesa tilkynninguna.
                                        </li>
                                        <li>
                                            gera tengilinn óvirkan eftir að hann
                                            hefur verið notaður.
                                        </li>
                                        <li>
                                            skilja eftir skilaboð sem birtast
                                            þegar tengill er opnaður.
                                        </li>
                                    </ul>
                                </div>
                                <div className={smallerMobile}>
                                    <img
                                        src='/features/reportinvite.png'
                                        alt=''
                                    />
                                </div>
                            </div>

                            <div className={itemLeft}>
                                <div className={larger}>
                                    <h3>Nafnlaus gátt</h3>
                                    <p>
                                        Nafnlaus gátt er stofnuð fyrir allar
                                        tilkynningar og er gáttin varin með
                                        sérstöku auðkennisnúmeri og lykilorði.
                                        Þar getur lögaðili óskað eftir frekari
                                        upplýsingum frá tilkynnanda um málið og
                                        tilkynnandi getur fylgst með framvindu
                                        málsins.
                                    </p>
                                    <ul>
                                        <li>
                                            Lögaðili og tilkynnandi geta átt
                                            samskipti og deilt skrám.
                                        </li>
                                        <li>
                                            Tilkynnandi getur fylgst með stöðu
                                            málsins, til dæmis hvort málið sé
                                            lokað eða í vinnslu.
                                        </li>
                                    </ul>
                                </div>
                                <div className={smaller}>
                                    <img
                                        src='/features/anonymousportal.png'
                                        className={smallImg}
                                        alt=''
                                    />
                                </div>
                                <div className={smallerMobile}>
                                    <img
                                        src='/features/anonymousportal.png'
                                        alt=''
                                    />
                                </div>
                            </div>

                            <div className={itemRight}>
                                <div className={smaller}>
                                    <img src='/features/audkenni.png' alt='' />
                                </div>

                                <div className={larger}>
                                    <h3>Auðkenning með rafrænum skilríkjum</h3>
                                    <p>
                                        Tilkynnendur geta auðkennt sig með
                                        rafrænum skilríkjum í gegnum Ísland.is
                                        ef lögaðili óskar eftir því.
                                    </p>

                                    <ul>
                                        <li>
                                            Lögaðili getur gert auðkenningu
                                            nauðsynlega með rafrænum skilríkjum,
                                            til að auka gæði tilkynningar og
                                            forðast ruslpóst.
                                        </li>
                                        <li>
                                            Lögaðili getur skráð sig inn með
                                            rafrænum skilríkjum.
                                        </li>
                                    </ul>
                                </div>
                                <div className={smallerMobile}>
                                    <img src='/features/audkenni.png' alt='' />
                                </div>
                            </div>

                            <div className={itemLeft}>
                                <div className={larger}>
                                    <h3>Nafnleynd</h3>
                                    <p>
                                        Móttakandi tilkynninga eða gagna skal
                                        gæta leyndar um persónuupplýsingar
                                        tilkynnanda nema viðkomandi veiti
                                        afdráttarlaust samþykki sitt.
                                    </p>
                                    <p>
                                        Umsýslukerfi Tilkynna birtir aldrei
                                        persónuupplýsingar tilkynnanda, nema
                                        þegar lögaðili óskar eftir þeim. Þegar
                                        lögaðili óskar eftir persónuupplýsingum
                                        tilkynnanda þá þarf hann að gefa ástæðu
                                        fyrir því sem verður skráð í
                                        atburðaskrá.
                                    </p>

                                    <ul>
                                        <li>
                                            Lögaðili getur ákveðið hvort hann
                                            vilji leyfa nafnlausar tilkynningar.
                                        </li>
                                        <li>
                                            Lögaðili getur hreinsað allar
                                            persónuupplýsingar úr tilkynningu
                                            þegar hún hefur verið afgreidd.
                                        </li>
                                        <li>
                                            Persónuupplýsingar tilkynnanda eru
                                            aldrei birtar nema þegar lögaðili
                                            óskar sérstaklega eftir þeim.
                                        </li>
                                    </ul>
                                    <p></p>
                                </div>
                                <div className={smaller}>
                                    <img
                                        src='/features/verified.png'
                                        className={smallImg}
                                        alt=''
                                    />
                                </div>
                                <div className={smallerMobile}>
                                    <img src='/features/verified.png' alt='' />
                                </div>
                            </div>

                            <div className={itemRight}>
                                <div className={smaller}>
                                    <img src='/features/overview.png' alt='' />
                                </div>

                                <div className={larger}>
                                    <h3>Yfirlitssíða</h3>
                                    <p>
                                        Yfirlitssíðan gefur lögaðila innsýn í
                                        núverandi stöðu og heildartölur fyrir
                                        árið, ásamt línuritum sem sýna mótteknar
                                        og lokaðar tilkynningar, fjöldi skipta
                                        sem merkimiðar eru notaðir, fjölda
                                        skipta sem umsjónaraðilar eru skráðir á
                                        tilkynningar og hvernig tilkynningar eru
                                        afgreiddar.
                                    </p>

                                    <ul>
                                        <li>
                                            Undir núverandi stöðu getur lögaðili
                                            séð fjölda yfir nýjar tilkynningar,
                                            opnaðar tilkynningar, tilkynningar í
                                            bið, ólesin skilaboð frá
                                            tilkynnendum og ólesnar
                                            athugasemdir.
                                        </li>
                                        <li>
                                            Undir helstu tölur fyrir árið getur
                                            lögaðili séð fjölda yfir
                                            tilkynningar, lokaðar tilkynningar
                                            og meðalafgreiðslutíma mála í virkum
                                            dögum.
                                        </li>
                                    </ul>
                                </div>
                                <div className={smallerMobile}>
                                    <img src='/features/overview.png' alt='' />
                                </div>
                            </div>

                            <div className={itemLeft}>
                                <div className={larger}>
                                    <h3>Atburðaskrá</h3>
                                    <p>
                                        Umsýslukerfi Tilkynna skráir allar
                                        aðgerðir inn í atburðaskrá sem ekki er
                                        hægt að eiga við. Þar getur lögaðili séð
                                        allar aðgerðir sem hafa verið
                                        framkvæmdar.
                                    </p>

                                    <ul>
                                        <li>
                                            Hægt er að sjá hver framkvæmdi
                                            aðgerðina, hvenær og í sumum
                                            tilvikum af hverju, t.d. þegar
                                            skoðaðar eru persónuupplýsingar
                                            tilkynnanda.
                                        </li>
                                        <li>
                                            Ekki er hægt að breyta eða fjarlægja
                                            aðgerðir sem hafa verið skráðar í
                                            atburðaskrána.
                                        </li>
                                    </ul>
                                </div>
                                <div className={smaller}>
                                    <img src='/features/activity.png' alt='' />
                                </div>
                                <div className={smallerMobile}>
                                    <img src='/features/activity.png' alt='' />
                                </div>
                            </div>
                        </div>
                    </section>
                    <GetStartedSection backgroundColor='#eee' />
                </div>
            </Layout>
        </>
    );
};

export default Features;
