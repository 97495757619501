import React from 'react';
import { Link } from 'gatsby';
import { useAppSelector } from '../../hooks';

interface Props {
    className: string;
    backgroundColor: string;
}

const GetStartedSection = ({ className, backgroundColor }: Props) => {
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const isSigningIn = useAppSelector((state) => state.global.isSigningIn);

    return (
        <section
            className={className}
            style={{
                backgroundColor,
            }}
        >
            <div style={{ textAlign: 'center' }}>
                <div>
                    <Link
                        className='btn large'
                        to={
                            isSignedIn || isSigningIn
                                ? `/account/overview`
                                : `/is/signup`
                        }
                    >
                        Prófa frítt í 30 daga
                    </Link>
                </div>
                <div style={{ marginTop: '4em' }}>
                    <Link className='btn text large' to={`/`}>
                        ← Fara aftur á forsíðu
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default React.memo(GetStartedSection);
